.style-table__clz {
    display: grid;
    min-height: 650px;
    grid-template-columns: auto;
    grid-column-gap: 4px;
    border: 0;
}
.modal-wrapper__clz .modal-content {
    height: 92vh !important;
    width: 95vw !important;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
}

