.fullscreen-bg-color__clz {
    background-color: #363636;
}

.escape-fullscreen-mode-button__clz {
    background-color: red !important;
    z-index: 1;
    border-top-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
}

.escape-fullscreen-mode-button__clz:hover {
    border: none !important;
}
