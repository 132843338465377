.nav-link-new-segment-active__clz {
    border-bottom: 2px solid black;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.ant-upload.ant-upload-select-picture-card {
    width: 429px;
    height: 103px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
}