.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.calorie {
    width: 100%;
    font: normal 400 12px/16px;
    font-family: Segoe UI;
    gap: 10px;
    min-height: 24px;
}
.price {
    font: normal bold 24px/29px Segoe UI;
    min-height: 35px;
}

.product-image {
    width: 50px;
}
.button__clz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70px;
    gap: 10px;
}
.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

:root {
    --height-workflow-header: 105px;
    --swiper-width: 0px;
}

.workflow-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 105px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #ed1c2482 100%)
        0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px #00000029;
    opacity: 1;
}

.workflow-product-title {
    height: 60px;
    text-align: left;
    font: normal normal bold 45px/60px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
}

.workflow-tree {
    width: 10px;
    height: 55px;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.workflow-footer {
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.workflow-basket {
    width: 100%;
    height: auto;
    background: #f8f8f8 0% 0% no-repeat padding-box;
}

.workflow-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.drop-item-button {
    width: 266px;
    height: 65px;
    border: 1px solid #05fee1;
    border-radius: 38px;
}

.drop-item-button-text {
    text-align: center;
    font: normal normal 600 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #6a92ff;
}

.workflow-total {
    text-align: center;
    font: normal normal 600 29px/38px Segoe UI;
    letter-spacing: 0px;
    color: #1f1f1f;
}

.workflow-timeline {
    /* margin-top: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.workflow-main-step-title {
    font: normal normal 600 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 5px;
    text-transform: lowercase;
}

.workflow-main-step-title:first-letter {
    text-transform: uppercase;
}

.workflow-step-title {
    font: normal normal 600 20px/27px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    padding-left: 5px;
    text-transform: lowercase;
}

.workflow-step-title:first-letter {
    text-transform: uppercase;
}

.workflow-step {
    background: #ededed 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    height: 40px;
}

.workflow-main-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.workflow-swiper-slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    gap: 15px;
}

.workflow-swiper-container .swiper-wrapper {
    height: 200px !important;
    padding-top: 0.25rem !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
}

.workflow-swiper-container-items-fewer-than-four .swiper-wrapper {
    transition-duration: 0ms !important;
    transform: translate3d(0px, 0px, 0px) !important;
}

.workflow-swiper-container.swiper-container {
    height: 300px !important;
    width: var(--swiper-width) !important;
    margin-left: unset !important;
    display: flex;
    align-items: center;
    margin-right: unset !important;
}

.step-item {
    border-radius: 50%;
    background-color: white;
    padding: 10px 13px;
    color: #00000052;
    transition: border-color color;
    text-align: center;
    height: 39px;
    width: 39px;
    outline: 3px solid var(--progress-color);
    outline-offset: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 19px/60px Segoe UI;
    box-shadow: inset 0px 0px 3px #0000000f, 0px 2px 6px #00000052;
    position: relative; /* absolute*/
    padding: unset !important;
}

.outer {
    background-color: #0000001c;
    height: 39px;
    width: 39px;
    position: relative;
    z-index: -3;
    top: 8px;
    left: 4px;
    animation: waves 1.5s infinite ease-in-out;
}

.check {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
}

.workflow-swiper-slide::after {
    content: "";
    width: 105px;
    height: 10px;
    background-color: white;
    position: absolute;
    top: 84px;
    left: 37px;
    box-shadow: inset 0px 0px 3px #0000000f, 0px 2px 6px #00000052;
    z-index: 1;
}

.workflow-swiper-slide:last-child::after {
    content: "";
    background-color: transparent;
    width: 0px;
}

.active-line {
    width: 53px;
    height: 25px;
    border-bottom: 3px solid var(--progress-color);
    position: absolute;
    top: 65.5px;
    left: 34px;
    z-index: 2;
    animation: lineActiveTrans 1s linear;
}

.completed-line {
    width: 225px;
    height: 47px;
    border-bottom: 3px solid #000000;
    position: absolute;
    top: 182px;
    left: 73px;
    z-index: 2;
    animation: lineCompletedTrans 2s normal forwards;
}

@keyframes lineActiveTrans {
    0% {
        width: 0px;
        /*transform: translateX(-50%);*/
    }
    50% {
        width: 70px;
    }
    100% {
        width: 106px;
        /* transform: translateX(50%); */
    }
}

@keyframes lineCompletedTrans {
    0% {
        width: 106px;
        /*transform: translateX(-50%);*/
    }

    50% {
        width: 180px;
    }

    100% {
        width: 225px;
        /* transform: translateX(50%); */
    }
}

@keyframes waves {
    to {
        box-shadow: 0 0 0 3px #0000000d, 0 0 0 6px #00000005, 0 0 0 10px #0000,
            0 0 0 6px #00000024;
    }
}

.inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    height: 30px;
    width: 30px;
    position: relative;
    border-radius: 50%;
}

.left-position {
    left: 20px;
}

.h-110 {
    height: 110px;
}

.non-active-step {
    outline: unset;
    z-index: 2;
}

.top-button-position {
    position: relative;
    top: 50px;
}

.step-image {
    gap: 15px;
    height: 144px;
    padding-right: 20px;
}

.w-1068 {
    width: 1068px;
}

.arrow-right,
.arrow-left {
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 5px solid gray;
    border-left: 5px solid gray;
}

.arrow-right {
    transform: rotate(135deg);
}

.arrow-left {
    transform: rotate(-45deg);
    margin-right: 20px;
}

.circle {
    border-radius: 50%;
}
