.loyalty-languages-table__clz {
    font-size: 15px;
}

.loyalty-languages-table__clz.table th {
    font-weight: normal;
    padding: 1px;
    max-height: 45px !important;
}

.loyalty-languages-table__clz table {
    width: 100%;
}

.loyalty-languages-table__clz.table td {
    padding: 0.4rem !important;
}

.loyalty-languages-table__clz thead tbody,
tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 45px !important;
}

.loyalty-languages-table__clz tbody {
    display: block;
    overflow: hidden auto;
    table-layout: fixed;
}

.loyalty-languages-table__clz table td {
    padding: 0.5rem !important;
}

.payment-amount-table__clz thead tbody,
tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 39px !important;
}

.payment__clz thead th {
    font-size: 0.6em;
    padding: 1px !important;
    height: 15px;
}