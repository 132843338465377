.input-cash__clz {
    top: 147px;
    left: 321px;
    width: 218px;
    height: 27px;
    text-align: left;
    font: normal normal 600 20px/24px Segoe UI;
    color: #000000;
    opacity: 1;
    margin-left: 12px;
}

.spinnerpercent ::before {
    z-index: 100000;
    content: "%";
    position: absolute;
    right: 49px;
    top: 10px;
    font-size: 15px;
}

.numInput__clz {
    width: 130px;
    height: 30px;
    text-align: center;
}
