.menu-wrapper-nav-link__clz {
    color: white;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 190px;
    height: 44px;
    text-align: justify;
}

.menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: auto;
}
li.menu-wrapper-items-nav-link-active__clz.active {
    background: #f8f9faa3;
}
a.menu-wrapper-items-nav-link-active__clz.active {
    color: whitesmoke !important;
    opacity: 1 !important;
    border-left: 2px solid whitesmoke !important;
}
.sub-menu-wrapper-items-nav-link-active__clz.nav-link.active {
    color: white !important;
    background-color: rgb(184, 183, 183) !important;
    border-color: unset !important;
    border: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    width: 100%;
}
.menu-wrapper-nav-link__clz:hover {
    color: rgb(187, 186, 186) !important;
}
