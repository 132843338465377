.border-Footer__clz {
    border-bottom: 1px solid #ced4da;
}
.card_cl__clz {
    display: grid;
    grid-template-columns: 2fr 2fr;
    column-gap: 4px;
    border: 0;
}
.style-card_clz {
    border-color: #ced4da !important;
    border-width: thin;
}
.card_c__clz {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    column-gap: 4px;
    border: 0;
}
.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;
}

.react-datepicker__clz {
    width: 73px !important;
    height: 30px;
    font-size: 14px;
}
.style-modal__clz {
    width: 650px;
    min-width: 700px;
}

.btn-style__clz:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem white !important;
}
.style-calender__clz {
    min-width: 200px !important;
    width: 200px !important;
}
.react-datepicker__header {
    text-align: center;
    background-color: white !important;
    border-bottom: 1px solid #f0e7e7;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
.react-datepicker__month-container {
    width: 320px;
    max-width: 320px;
    overflow: auto;
    height: 274px;
    max-height: 274px;
}
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1rem;
    color: #000;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #0c0a09 !important;
    color: #fff;
}
