.arrow-button__clz:hover {
    background-color: transparent !important;
    border: none !important;
}

.item-index__clz {
    display: grid;
    grid-template-columns: 0.2fr 1.8fr;
    font-size: 23px;
    box-shadow: 0px 3px 6px #00000029;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.margin-unset__clz.inznaY {
    margin: 0px !important;
}
