.keyboard__clz.hg-theme-default.hg-layout-numeric .hg-button {
    font: normal normal bold 23px/27px Arial;
    align-items: center;
    display: flex !important;
    justify-content: center !important;
    font: normal normal bold 23px/27px Arial;
    justify-content: center !important;
    width: 38px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000052;
    border-radius: 10px;
}

.keyboard__clz.hg-theme-default .hg-button span {
    width: 6px;
    height: 14px;
    text-align: center;
    font: normal normal bold 12px/14px Arial;
    letter-spacing: 0px;
    color: #000000;
}
