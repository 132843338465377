.template-container__clz {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1.9fr 0.1fr;
}

.w-40__clz {
    width: 40%;
}

.h-40__clz {
    height: 40%;
}

.w-30__clz {
    width: 30%;
}

.h-30__clz {
    height: 30%;
}

.config-options-icon__clz {
    height: 2em;
    width: 2em;
    fill: white;
}

.template-swiper-container__clz.swiper-container {
    width: 455px;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

.language-swiper-slide__clz.swiper-slide {
    width: 33% !important;
    margin-right: 20px !important;
}

.language-swiper-slide-img__clz.swiper-slide img {
    display: block;
    width: 100%;
    object-fit: contain;
}

.title-language__clz {
    font-size: 15px;
    font-weight: bold;
}
.kiosk-languages-swiper-container__clz .swiper-wrapper {
    align-items: end;
}
