.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center;
}

.calorie {
    width: 100%;
    font: normal 400 12px/16px;
    font-family: Segoe UI;
    gap: 10px;
    min-height: 24px;
}
.price {
    font: normal bold 24px/29px Segoe UI;
    min-height: 35px;
}

.product-image {
    width: 50px;
}
.button__clz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70px;
    gap: 10px;
}
.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
