@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(395deg);
    }
}
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #b6b2b2 !important;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    animation-duration: 1s;
}
