.header-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 425px;
    height: 30px;
    box-shadow: 0px -5px 6px #2c2c2c15;
    border-bottom: 1px solid rgba(112, 112, 112, 0.23);
}
.cart-icon {
    width: 40px;
    height: 70px;
    text-align: center;
}
.number-articles {
    display: inline-block;
    margin-left: 15px;
    font: normal normal 300 9px/18px Segoe UI;
}

.label-open-cart {
    position: absolute;
    top: 53%;
    left: 40%;
}
.cart-pmr-button {
    display: flex;
    width: auto;
    height: 58px;
    justify-content: flex-end;
}
.help-icon {
    cursor: pointer;
}
.pmr-icon {
    cursor: pointer;
}
.footer-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 425px;
    text-align: center;
    font: normal normal 600 10px/24px Segoe UI;
    letter-spacing: 0px;
    color: #b7b7b7;
    opacity: 1;
}
.footer-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(112, 112, 112, 0.23);
    opacity: 0.23;
    opacity: 1;
    height: 97px;
}
.cancel-order {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 258px;
    height: 73px;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #8b8b8b;
    opacity: 1;
}
button[disabled="disabled"],
button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    animation-name: disableButtonAnimation;
    animation-duration: 0.3s;
}
@keyframes disableButtonAnimation {
    0% {
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
    }
    100% {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.done-order {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 760px;
    height: 73px;
    background: #ff8205 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    opacity: 1;
}
.label-done-order {
    width: 76px;
    height: 29px;
    text-align: center;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.total-order {
    width: 150px;
    height: 29px;
    text-align: right;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

/***** end cart style ****/

/***** popup cart style ********/
.header-popup {
    position: absolute;
    z-index: 1000;
    width: 1080px;
    background: white;
    top: 0;
    /* padding-top: 50px; */
    height: 200px;
}

.footer-button-popup-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(112, 112, 112, 0.23);
    opacity: 0.23;
    opacity: 1;
    height: 97px;
    position: fixed;
    bottom: 0;
    padding-left: 7px;
    padding-right: 7px;
    width: 1080px;
    background-color: #ffffff;
}

.cancel-order-popup-card {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 258px;
    height: 73px;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    font: normal normal 600 29px/35px Segoe UI;
    letter-spacing: 0px;
    color: #8b8b8b;
    opacity: 1;
    margin-left: 10px;
}
.done-order-popup-card {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 770px;
    height: 73px;
    margin-right: 20px;
    background: #ff8205 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #8b8b8b;
    border-radius: 15px;
    opacity: 1;
}
.label-close-cart {
    position: absolute;
    top: 53%;
    left: 40%;
}
.title-popup-cart {
    font: normal normal 600 58px/70px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: center;
    height: 58px;
    margin-top: 80px;
}
.number-poroduct-in-cart {
    text-align: center;
    font: normal normal normal 28px/34px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 20px;
    height: 28px;
}
.simple-product {
    position: relative;
    margin: 0 auto;
    margin-bottom: 44px;
    width: 982px;
    min-height: 319px;
    /* UI Properties */
    background: #fdfdfc 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000080;
    border: 1px solid #f8f8f6;
    opacity: 1;
    border-radius: 15px;
}
.product-header {
    margin-top: 18px;
    margin-left: 28px;
    margin-right: 28px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.number-product {
    text-align: center;
    font: normal normal 600 40px/48px Segoe UI;
    letter-spacing: 0px;
    color: #ff8205;
    display: inline-block;
    margin-right: 15px;
}
.product-title {
    font: normal normal 600 40px/48px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
}
.price-product {
    font: normal normal 600 35px/42px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 103px;
}
.product-info-container {
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product-info {
    display: flex;
}
.list-compositions {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    padding-top: 30px;
    margin-left: 25px;
}
.list-compositions > div {
    flex: 1 150px;
    text-align: left;
    font: normal normal normal 23px/28px Segoe UI;
    letter-spacing: 0px;
    color: #0d0c0c;
    opacity: 1;
}
.view-more {
    position: absolute;
    right: 7px;
    bottom: 19px;
    border: 0px;
    cursor: pointer;
    align-self: flex-end;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 43px;
    background: #ff8205 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
}
.imag-product {
    height: 155px;
    width: 116px;
    margin-left: 70px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imag-product-component {
    height: 108px;
    width: 133px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imag-icon-product {
    height: 65px;
    width: 65px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.edit-product {
    height: 93px;
    align-self: center;
    margin-bottom: 10px;
}
.label-edit-product {
    font: normal normal bold 16px/22px Nunito;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.product-operations > * {
    cursor: pointer;
}
.product-operations {
    display: flex;
}
.details-component {
    width: 565px;
}
.cart-products-list {
    max-height: 1626px;
    overflow-y: auto;
    padding-top: 250px;
}
.product-component {
    height: 217px;
    width: 888px;
    margin: 0 auto;
    margin-top: 34px;
}
.product-component:last-of-type {
    margin-bottom: 117px;
}
.info-component {
    display: flex;
    width: 888px;
    height: 170px;
    padding-top: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 15px;
    opacity: 1;
}
.info-container-product {
    height: 144px;
}
.info-container-product .title-product-preview {
    margin-top: 23px;
}
.simple-product-operations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin: unset;
}
.number-components {
    text-align: center;
    margin-right: 10px;
    font: normal normal bold 30px/36px Segoe UI;
    letter-spacing: 0px;
    color: #ff8205;
    opacity: 1;
}
.component-name {
    font: normal normal bold 30px/36px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 8px;
}
.basic-compositions-component {
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 8px;
}
.variants-component {
    width: 500px;
    /* UI Properties */
    font: normal normal normal 18px/22px Segoe UI;
    color: #3cc73a;
}
.title-component {
    font: normal normal bold 35px/47px Nunito;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
/*** end style for popup cart *****/
/*** swiperproduct cart ***/
.swiper-product-cart {
    width: 1050px;
    height: 218px;
}
.product-swiper {
    width: 334px;
    height: 214px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
}
.imag-product-preview {
    height: 84px;
    width: 112px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 10px;
    margin-top: 5px;
}
.info-image-price {
    display: flex;
    justify-content: space-between;
    width: 309px;
}
.price-product-preview {
    font: normal normal bold 22px/26px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 15px;
}
.title-product-preview {
    font: normal normal bold 20px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
    text-align: left;
    margin-top: 5px;
    margin-left: 20px;
}
.compositions-product-preview {
    font: normal normal 300 15px/18px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-left: 20px;
}
.actions-product-preview {
    display: flex;
    justify-content: flex-end;
    border-top: 5px solid #f5f5f5;
    width: "140px";
}
.view-more-product-preview {
    border: 0px;
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 50px;
    background: #ff8205 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal normal normal 17px/20px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
    align-self: center;
}
.number-product-preview {
    font: normal normal bold 22px/26px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    align-self: center;
}

/*** end swiper product ****/
.productContainer__clz {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.1fr;
    grid-template-rows: 1.6fr 0.3fr 0.3fr 0.1fr 1fr;
}

.centredItems__clz {
    display: flex;
    justify-content: center;
    align-items: center !important;
}

/*** test ***/

@keyframes fadeInDown {
    0% {
        z-index: -10;
        transform: translateY(-55%);
    }
    20% {
        transform: translateY(-60%);
    }
    90% {
        transform: translateY(5%);
    }
    100% {
        z-index: 0;
        transform: translateY(0);
    }
}

.category-swiper-slide__clz {
    transform: translateY(0);
    animation-name: fadeInSlideDown;
    animation-duration: 3s;
}

@keyframes fadeInSlideDown {
    0% {
        opacity: 0.5;
    }
    /* 50% {
        opacity: 0.7;
    } */
    100% {
        opacity: 1;
    }
}

.category-swiper__clz {
    transform: translateY(0);
    animation-name: fadeInDown;
    animation-duration: 2s;
}

@keyframes fadeInDownSlideElement {
    0% {
        opacity: 0.15;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.product-swiper {
    animation-name: fadeInDownSlideElement;
    animation-duration: 1.5s;
}
