.active-tab__clz {
    color: black !important;
}
.border-footer__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}

.modal-dialog-printer__clz .modal-content {
    height: 280px !important;
}

.border-footer-maintenace__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 23px !important;
}
.border-footer-inventar__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin-top: 51px !important;
}
.active-tab__clz {
    border-bottom: 3px solid black !important;
}
.input-file___clz {
    width: 72%;
    height: 29px !important;
}

.text-spinner__clz {
    width: 70px !important;
    height: 30px !important;
    font-size: 16px;
    border-radius: 10px !important;
    -moz-appearance: textfield !important;
    text-align: center;
}
.border-footer-Valina__clz {
    border-top: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.spinner-btn__clz {
    min-width: 10px !important;
    min-height: 10px !important;
    border-radius: 24px !important;
    margin: 3px 5px 3px 1px !important;
    height: 25px;
    width: 24px;
}

.form-group {
    margin-bottom: 0px !important;
}

.btn_upload__clz {
    min-width: 40px;
    min-height: 25px;
    position: relative;
    top: 25px;
}

.card-header__clz {
    padding-bottom: 2px !important;
    box-shadow: -1px 0px 6px #d5d1d1;
    height: 66px !important;
}

.card-footer__clz {
    background-color: transparent !important;
}

.row__clz {
    padding-right: 11px;
    padding-left: 11px;
    border-radius: 9px;
}
.card-footer__clz {
    background-color: white !important;
}
.card-body__clz {
    padding: 0px !important;
}
.card__clz {
    box-shadow: 0 0.75rem 0rem rgb(251 251 251 / 0%) !important;
}
.body_modal__clz {
    min-height: 700px;
}
#input-spinner-right-button {
    border-radius: 49px;
    width: 29px;
}
#input-spinner-left-button {
    border-radius: 49px;
    width: 29px;
}
.input-group:not(.has-validation) > .form-control:not(:last-child) {
    width: 117px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 6px !important;
    text-align: center;
    font-size: 13px;
}

.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.not-allowed-icon__clz {
    pointer-events: none;
    opacity: 0.4;
}
.input-styled__clz {
    width: 286px !important;
}
.input-qr-styled__clz {
    width: 288px !important;
    margin-left: 91px !important;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
    width: 16px;
    height: 15px;
}
.modal-wrapper-width__clz .modal-content {
    width: 95vw !important;
    height: auto !important;
}

.border-footer-state__clz {
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    margin: 0px !important;
}
.display-none__clz {
    display: none !important;
}
