.general-container__clz {
    height: 100vh;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "nav main";
}

.non-collapsed-container__clz {
    grid-template-columns: 250px auto;
}

.collapsed-container__clz {
    grid-template-columns: 72px auto;
}

.d-grid__clz {
    display: grid !important;
}

.cursor__clz {
    cursor: default;
}
