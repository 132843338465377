.th-costum-table__clz {
    background: #f8f8fb !important;
    font-weight: 700 !important;
    padding: 0px 0px 0px 12px !important;
    border: 0.5px solid #e0e0e0 !important;
}
.MuiTablePagination-displayedRows {
    margin: 0px !important;
}

.MuiTablePagination-selectLabel {
    margin: 0px !important;
}
.costum-table-cell__clz {
    padding: 2px 2px 2px 10px !important;
}
table.costumTable > tbody > tr {
    height: 21px !important;
    min-height: 21px !important;
}
tfoot.MuiTableFooter-root > tr {
    height: 30px !important;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
    min-height: 40px !important;
    padding-right: 2px !important;
}
.dropdown-costum-table__clz {
    position: fixed !important;
    top: auto !important;
    left: 98vh !important;
    width: fit-content;
    z-index: 9999999999999 !important;
}
.MuiTablePagination-displayedRows {
    display: none !important;
}
.disabled-label__clz {
    pointer-events: none;
}
table.costumTable > thead.MuiTableHead-root > tr {
    height: 10px !important;
}

.table-drp-button__clz {
    padding: 1px !important;
}
.tab-content.m-3 {
    height: 100%;
    min-height: 100%;
    margin: 0px !important;
}
.tab-content.m-3 > .tab-pane.active {
    height: 100%;
}
