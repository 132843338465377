.logo__clz {
    position: absolute;
    left: 18px;
    top: 22px;
}
.smallLogo__clz {
    position: absolute;
    left: 21px;
    top: 22px;
}

ul.sub-menu > li.mm-active > a.active {
    border-left: 3px solid white;
    background-color: #ecefef24;
}

.link-div__clz {
    border-left: 1px solid #1f3dd0;
    padding-left: 9px;
}
